import React from 'react'
import Button from 'react-bootstrap/Button';


const WaitingList = (props) => (
	<form name="waiting-list" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
		<input type="hidden" name="form-name" value="waiting-list" />
		<label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="email">Enter your email to stay posted on Paintly:</label>
		<input className="shadow appearance-none border rounded py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline" type="email" name="email" id="email" required></input>
		{/* <button className="btn btn-blue btn-primary ml-auto mr-10" type="submit">Send</button> */}
		<Button variant="primary" type="subit">Send</Button>{' '}
	
	</form>
)


export default WaitingList
