import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import logo from "../images/oasis-logo.svg"
import WaitingList from '../components/form-waiting-list'


const Header = (props, { siteTitle }) => (
  <header className = "header-wrapper pb-5 pt-5">

    <Link
      to="/"
      style={{
        textDecoration: 'none',
      }}
    >
      {siteTitle}
      <img src={logo} alt="Paintly" className="logo pl-10"/>
    </Link>

    <ul className="nav font-primary uppercase">
      <li className="nav__item"><a href="">Painting Kits</a>
      </li>
      <li className="nav__item"><a href="">Gallery</a>
      </li>
      <li className="nav__item"><a href="">Contact</a>
      </li>
    </ul>

    <button className="btn btn-blue ml-auto mr-10">
      Pre Order Kit
    </button>
    <WaitingList/>

  </header>
)

export default Header



